import React, { useState } from 'react';
import { Button as ReactstrapButton } from 'reactstrap';

const backgroundColors = {
    beige: '#e6dfd3',  
    orange: '#FF9800',  
    yellow: '#FFD600',
    brownishGray: '#6c5a4f',
    danger: '#ff5c5c' 
};

// Función para oscurecer un color
const darkenColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};

// Función para determinar si se debe usar texto negro o blanco basado en el color de fondo
const getContrastColor = (hexColor) => {
    // Convertir el color hexadecimal a RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    // Calcular la luminancia relativa
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    // Retornar negro para colores claros, blanco para colores oscuros
    return luminance > 0.5 ? '#000000' : '#ffffff';
};

export const CustomButton = ({ size, colorFondo, onClick, icon: Icon, children, disabled, style }) => {
    const [isHovered, setIsHovered] = useState(false);

    // Usar transparente si no se proporciona colorFondo
    const baseColor = colorFondo ? backgroundColors[colorFondo] || backgroundColors.brownishGray : 'transparent';
    const hoverColor = colorFondo ? darkenColor(baseColor, 20) : '#e6dfd3';
    const textColor = baseColor === 'transparent' ? '#6c5a4f' : getContrastColor(baseColor);

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: children ? 'flex-start' : 'center', // Centrar si no hay texto
        color: textColor,
        backgroundColor: isHovered && !disabled ? hoverColor : baseColor,
        border: 'none',
        transition: 'background-color 0.3s ease',
        padding: children ? '0.5rem 1rem' : '0.5rem', // Ajustar padding si no hay texto
        fontWeight: 'bold',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style, // Aplicar estilos adicionales pasados como prop
    };

    const iconStyle = {
        marginRight: children ? '5px' : '0', // No añadir margen derecho si no hay texto
        display: 'block', // Asegurar que el ícono se muestre como bloque
    };

    return (
        <ReactstrapButton 
            onClick={onClick} 
            style={buttonStyle} 
            size={size}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => !disabled && setIsHovered(false)}
            disabled={disabled}
        >
            {Icon && <Icon size={16} style={iconStyle} />}
            {children}
        </ReactstrapButton>
    );
};