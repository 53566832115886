import React, { useState, useEffect, useRef } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';

export const DropdownSelect = ({ field, options, displayKey, value, onChange, placeholder, initialValue, getOptionValue, getDisplayValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (initialValue) {
            setFilterText(getOptionDisplay(initialValue));
        }
    }, [initialValue]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const getOptionDisplay = (option) => {
        if (getDisplayValue) {
            return getDisplayValue(option);
        }
        if (typeof option === 'string') return option;
        return option[displayKey] || option.nombre || option.value || option;
    };

    const handleInputChange = (e) => {
        setFilterText(e.target.value);
        onChange(field, e.target.value, null);
    };

    const handleOptionSelect = (option) => {
        const selectedValue = getOptionDisplay(option);
        const id = getOptionValue(option);
        onChange(field, selectedValue, id);
        setFilterText(selectedValue);
        setIsOpen(false);
    };

    const filteredOptions = options.filter(option => 
        getOptionDisplay(option).toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="position-relative" ref={dropdownRef}>
            <Input
                type="text"
                name={field}
                id={field}
                value={filterText}
                onChange={handleInputChange}
                onClick={() => setIsOpen(!isOpen)}
                autoComplete="off"
                style={{ cursor: 'pointer' }}
                placeholder={placeholder || `Select ${displayKey}`}
            />
            {isOpen && (
                <ListGroup
                className="position-absolute w-100 mt-1 shadow-sm custom-listgroup"
                style={{ zIndex: 9999, maxHeight: '160px', overflowY: 'auto' }}
                >
                    {filteredOptions.map((option, index) => (
                        <ListGroupItem
                            key={index}
                            action
                            onClick={() => handleOptionSelect(option)}
                            className="custom-listgroup-item"
                            style={{ cursor: 'pointer' }}
                        >
                            {getOptionDisplay(option)}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )}
        </div>
    );
};